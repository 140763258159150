import React from "react";
import StakingPage from "./pages/StakingPage";

function Main() {
  return (
    <div>
      <StakingPage />
    </div>
  );
}

export default Main;
