import { ConnectKitButton } from "connectkit";
import { useAccount } from "wagmi";
import { useWeb3Modal } from "@web3modal/react";
import { WalletIcon } from "lucide-react";
const Header = () => {
  const { address, isDisconnected } = useAccount();

  const { open } = useWeb3Modal();

  const ConnectButton = () => {
    return (
      <ConnectKitButton.Custom>
        {({ isConnected, show, truncatedAddress }) => {
          return (
            <div onClick={show}>
              {isConnected ? (
                <button
                  onClick={() => open()}
                  className=' bg-vgray border-1 border border-vyellow py-2 md:my-0 my-1  text-white   px-4 md:px-[29px] rounded-md text-xs    '
                >
                  <span className='  text-white  '>
                    {address?.slice(0, 6)}...{address?.slice(-4)}
                  </span>
                </button>
              ) : (
                <p className='cursor-pointer bg-vgray border-1 border border-vyellow py-2 md:my-0 my-1  text-white   px-4 md:px-11 rounded-md text-xs md:text-sm   '>
                  Connect
                </p>
              )}
            </div>
          );
        }}
      </ConnectKitButton.Custom>
    );
  };
  return (
    <div className=' px-4 md:px-8  mx-auto  '>
      <ConnectButton />
    </div>
  );
};

export default Header;
