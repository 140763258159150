import React from "react";

function Loader() {
  return (
    <div
      className='bg-black min-h-screen flex gap-8 flex-col mx-auto items-center justify-center relative'
      style={{ zIndex: "9999" }}
    >
      <img
        src='https://images.versx.io/public/logo.svg'
        alt='logo'
        className='w-full h-full md:h-full px-8 mx-auto md:w-1/2  '
      />{" "}
      <h1 className='text-xl md:text-6xl text-white font-bold uppercase font-poppins'>
        Ethereum Staking
      </h1>
      <h1 className='text-xl text-white font-medium animate-pulse  font-poppins'>
        Loading...
      </h1>
    </div>
  );
}

export default Loader;
