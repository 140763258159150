import { useEffect, useState } from "react";

import Main from "../Main.js";
import Footer from "../Footer.tsx";
import Navbar from "./Navbar.tsx";
function Layout() {
  const [error, setError] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  return (
    <div className='  relative-positioning'>
      <div className='overlay'></div>
      <Navbar />{" "}
      <iframe
        src='https://statsfooter.versx.io/'
        style={{
          zIndex: "1",
          position: "relative",
          width: "100%", // Full width
          height: "100%", // Specify the height
          border: "none", // No border
          overflow: "hidden", // Hide scrollbars
        }}
      />{" "}
      <div className='  md:px-36 mx-auto   px-5 justify-start    relative  '>
        <h1 className=' my_heading text-left  flex gap-2 '>
          <img src='/chains/5.svg' className='md:w-8 my-auto md:h-8 w-5 h-5' />
          Ethereum Staking
        </h1>{" "}
      </div>
      <div className='  px-4 md:px-36  mx-auto relative z-10  flex pb-48 flex-col min-h-screen'>
        <Main setError={setError} setErrMsg={setErrMsg} />
      </div>{" "}
      <iframe
        src='https://statsfooter.versx.io/footer'
        style={{
          zIndex: "1",
          position: "relative",
          width: "100%", // Full width
          height: "100vh", // Specify the height
          border: "none", // No border
          overflow: "hidden", // Hide scrollbars
        }}
      />
    </div>
  );
}

export default Layout;
